import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Container, styled } from '@mui/material';
import { H1Typography, H1Typographysm, Body1Typography } from './Fonts';
import * as contentful from 'contentful'
import BlogCard from './BlogCard';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const client = contentful.createClient({
  space: '58j0xgm0wgec',
  environment: 'master',
  accessToken: 'FGQSEhrr-KtwrX9Su6RKMFHxrQ-mYk_q7Sl73Gy6S4E'
})

function BlogPage() {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  
  const [blogList, setBlogList] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    client.getEntries()
    .then((entry) => {
      // console.log(entry.items)
      setBlogList(entry.items);
      setLoading(false);
    })
    .catch((error) => {
      console.error('Error fetching blog data:', error);
      setLoading(false);
      setBlogList([]);
    });
  }, []);

  const handleCardClick = (article) => {
    navigate(`/blogs/${article.sys.id}`, { state: { article } });
  };

  return (
    <Container>
      {isSmallScreen?
      <Box sx={{display: 'flex', alignItems:'center', flexDirection: 'column', paddingBottom:'60px'}}>
        <H1Typographysm sx={{paddingTop:'120px', paddingBottom:'20px'}}>Blog</H1Typographysm>
        <Body1Typography sx={{textAlign:'center'}}>Gabriel AI revolutionizes communication with personalized voicemails and SMS messages tailored to individual preferences saving your time and enhancing engagement.</Body1Typography>
      </Box>:
      <Box sx={{display: 'flex', alignItems:'center', flexDirection: 'column', paddingBottom:'60px'}}>
        <H1Typography sx={{paddingTop:'120px', paddingBottom:'20px'}}>Blog</H1Typography>
        <Body1Typography sx={{width:'800px',textAlign:'center'}}>Gabriel AI revolutionizes communication with personalized voicemails and SMS messages tailored to individual preferences saving your time and enhancing engagement.</Body1Typography>
      </Box>
      }
      <Box sx={isSmallScreen?{display: 'flex', flexDirection:'column', gap:'30px', paddingBottom: '120px', alignItems:'center'}:{display: 'flex', flexWrap:'wrap', paddingBottom: '200px', justifyContent:'space-between', gap:'30px'}}>
        {loading?<div>Loading...</div>:
        blogList.map((article) => (
          <Box key={article.sys.id} onClick={() => handleCardClick(article)}>
            <BlogCard article={article} />
          </Box>
        ))
      }
      </Box>
    </Container>
  )
};


export default BlogPage;
