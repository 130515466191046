// src/components/BlogPage.js
import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import { Body1Typography, H1Typography, H2Typography, TimeText, Title, Bodytext, Subtitle } from './Fonts';
import TwitterShareButton from './TwitterShareButton';
import { useLocation, useNavigate} from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import styles from './Styles';

function BlogDetail() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }).format(date).toUpperCase();
  };

  const article = location.state?.article;
  if (!article) {
    navigate('/blogs/');
    return null;
  }
  const { title, posttime, maintext, image } = article.fields;

  return (
    <>
      <Box sx={{ height: '380px', backgroundColor: '#F8F6F7' }}>
        <Container sx={isSmallScreen?{...styles.containerStyleSm}:{...styles.containerStyle}}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {isSmallScreen?<Title className="title" sx={{ fontSize: '30px' }}>{title}</Title>:
            <Title className="title" sx={{ fontSize: '40px' }}>{title}</Title>}
            <TimeText sx={{ }}>Posted on {formatDate(posttime)}</TimeText>
            <TwitterShareButton />
          </Box>
        </Container>
      </Box>
      <Container className="maintext" sx={isSmallScreen?{...styles.containerStyleSm, paddingTop: '20px', paddingBottom: '120px' }:{...styles.containerStyle, paddingTop: '80px', paddingBottom: '120px' }}>
        {maintext.content.map((block, index) => {
          if (block.nodeType === 'paragraph') {
            return (
              <Bodytext key={index}>
                {block.content.map((subBlock, subIndex) => subBlock.value).join('')}
              </Bodytext>
            );
          } else if (block.nodeType === 'heading-2') {
            return (
              <Subtitle key={index}>
                {block.content.map((subBlock, subIndex) => subBlock.value).join('')}
              </Subtitle>
            );
          } else if (block.nodeType === 'ordered-list') {
            return (
              <ol key={index} style={{ paddingLeft: '20px' }}>
                {block.content.map((listItem, listIndex) => (
                  <li key={listIndex} style={{ marginBottom: '10px', fontSize: '20px' }}>
                    {listItem.content.map((subBlock) => subBlock.content.map((text) => text.value).join('')).join('')}
                  </li>
                ))}
              </ol>
            );
          } else {
            return null;
          }
        })}
        <Box sx={{ width: '100%', maxWidth: '500px', margin: '0 auto', paddingTop: '40px' }}>
          <img
            src={`https:${image.fields.file.url}`}
            alt={image.fields.title}
            style={{ width: '100%', height: 'auto',borderRadius: '20px' }}
          />
        </Box>
      </Container>
    </>
  );
}

export default BlogDetail;
