import React from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { H3Typography, Body1Typography } from './Fonts';

function BlogCard({ article }) {
  return (
    <Link to={`/blogs/${article.sys.id}`} style={{ textDecoration: 'none' }}>
      <Box
        sx={{
          backgroundColor: '#F8F6F7',
          height: '100%',
          width: '360px',
          padding: '20px',
          borderRadius: '20px',
          display: 'flex',
          flexDirection: 'column',
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          '&:hover': {
            transform: 'scale(1.01)',
            boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.2)',
          },
        }}
      >
        <img src={article.fields.image.fields.file.url} style={{ width: '100%', borderRadius: '15px' }} alt={article.fields.title} />
        <H3Typography sx={{ paddingTop: '25px' }}>
          {article.fields.title}
        </H3Typography>
        <Body1Typography sx={{ paddingTop: '10px' }}>
          {article.fields.subtitle}
        </Body1Typography>
      </Box>
    </Link>
  );
}

export default BlogCard;
