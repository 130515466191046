import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

import Navbar from './components/Navbar';
import Herosection from './components/Herosection';
import Partners from './components/Partners';
import Benefits from './components/Benefits';
import Features from './components/Features';
import Testimonial from './components/Testimonial';
import Ourprice from './components/Ourprice';
import WhyUs from './components/WhyUs';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import BlogPage from './components/BlogPage';
import BlogDetail from './components/BlogDetail';
import TestimonialVideo from './components/TestimonialVideo';

function Home() {
  return (
    <div>
      <Herosection />
      <Partners />
      <Benefits />
      <Features />
      <Testimonial />
      <TestimonialVideo />
      <Ourprice />
      <WhyUs />
      <ContactForm />
    </div>
  );
}

function Blogs() {
  return (
    <BlogPage />
  );
}

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:id" element={<BlogDetail />} />
        {/* <Route path="*" element={<h1>404: Page Not Found</h1>} /> */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
