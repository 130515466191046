import React, { useEffect } from 'react';

function TwitterShareButton() {
  useEffect(() => {
    // Inject the Twitter widget script
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.async = true;
    script.charset = 'utf-8';
    document.body.appendChild(script);

    // Cleanup when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <a
        href="https://twitter.com/share?ref_src=twsrc%5Etfw"
        className="twitter-share-button"
        data-size="large"
        data-show-count="false"
      >
        Tweet
      </a>
    </div>
  );
}

export default TwitterShareButton;
