import React from 'react';
import { Box } from '@mui/material';
import { Container } from '@mui/material';
import { H2Typography, Body1Typography, H3Typography, H2TypographySm } from './Fonts'
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import styles from './Styles';

function TestimonialVideo() {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container id='Video' sx={isSmallScreen?{...styles.containerStyleSm}:{...styles.containerStyle}}>
      <Box sx={{display: 'flex', justifyContent:'center',alignItems:'center', flexDirection:'column'}}>
        {isSmallScreen? 
        <H2TypographySm>
          Testimonial
        </H2TypographySm>:
        <H2Typography>
          Testimonial
        </H2Typography>}
        {/* <Body1Typography sx={{paddingTop:'5px'}}>
        Here is the testimonial from  XXXX.
        </Body1Typography> */}
        <div style={isSmallScreen?{height: '30vh', paddingTop: '30px'}:{height: '60vh', width: '40vw', paddingTop: '30px'}}>
          <iframe
            src="https://www.loom.com/embed/36667c0eda534b058414f5922b89fcb8?sid=565ef2ee-9f60-4649-8d4e-c2f269bb5d8b&hideEmbedTopBar=true"
            frameBorder="0"
            webkitAllowFullScreen
            mozAllowFullScreen
            allowFullScreen
            style={{
              width: '100%',
              height: '100%',
            }}
          ></iframe>
        </div>
      </Box>
    </Container>
  )
}

export default TestimonialVideo;